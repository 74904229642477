import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination, Navigation, Autoplay, Virtual} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

interface IBanner {
  id: number;
  img: string;
  title: string;
  description: string;
}

export const BannerBlock: React.FC = () => {
  const [banners, setBanners] = useState<IBanner[]>([]);

  useEffect(() => {
    const urlMainPageBanners:any = new URL("/db/mainPageBanners.json", origin);
    fetch(urlMainPageBanners)
      .then(response => response.json())
      .then(data => setBanners(data));
  }, []);

  return (
    <div className="banner-block">
      <Swiper
        modules={[Pagination, Navigation, Autoplay, Virtual]}
        pagination={{
          clickable: true
        }}
        navigation={{
          nextEl: '.custom-swiper-button-next',
          prevEl: '.custom-swiper-button-prev',
        }}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter:true
        }}
        spaceBetween={30}
      >
        <div className="custom-swiper-button-next"></div>
        <div className="custom-swiper-button-prev"></div>
        {banners.length >=1 && banners.map((item, index) => <SwiperSlide key={item.id} virtualIndex={index}>
          <div className="banner-title">
            <h3>{item.title}</h3>
            <span className="description">{item.description}</span>
          </div>
          <img src={item.img} alt={item.title}/>
        </SwiperSlide>)}
      </Swiper>
    </div>
  );
}