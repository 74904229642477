import React, {useEffect, useState} from 'react';
import {useParams, Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {ReactComponent as Reviews} from "../assets/image/icons/reviews.svg";
// import {ReactComponent as Pencil} from "../assets/image/icons/pencil.svg";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
// import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import {ReactComponent as VerifyIcon} from "../assets/image/icons/verifiedBig.svg";
import {ExpertOnMap} from "../components/ExpertOnMap";

// import {ExpertGallery} from "../components/ExpertGallery";

interface ProfilePageProps {
  users: IUser[],
  lang: string
}

export const ProfilePage: React.FC<ProfilePageProps> = ({users, lang}) => {
  const {t} = useTranslation("translation");
  const {userId} = useParams();
  const [user, setUser] = useState<IUser | null>(null);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [sinceDate, setSinceDate] = useState();
  const [sinceMonth, setSinceMonth] = useState();
  const [sinceYear, setSinceYear] = useState();

  useEffect(() => {
    if (users.length) {
      const userIndex = users.findIndex(item => item.id === userId);
      setUser(users[userIndex]);
    }
  }, [users]);

  useEffect(() => {
    if (user?.since) {
      // @ts-ignore
      setSinceDate(new Date().getDate(user.since));
      // @ts-ignore
      setSinceMonth(new Date().getMonth(user.since));
      // @ts-ignore
      setSinceYear(new Date().getFullYear(user.since));
    }
  }, [user]);

  return <div className={"profile-page"}>
    <h1>{t("profile page.title")}</h1>
    {user && <div className="content">
      <header>
        <div className="user-avatar-block">
          <img src={user.avatar} alt="avatar" className="avatar"/>
          <div className="username-block">
            <div className="username-wrapper">
              <p className="username"><span>{user.name}</span> <span className="user-int">{user.lastName[0]}.</span></p>
              {user.verified ? <VerifyIcon/> : <></>}
            </div>
            <p className="user-description">{user.description}</p>
            <button type={"button"} className={"send-message"}>{t("profile page.send message")}</button>
            <button type={"button"} className={"offer-button"}>{t("profile page.job offer")}</button>
          </div>
        </div>
        <section className="user-self-block">
          <h3>{t("profile page.about")}</h3>
          <div className="user-about-block">
            <p className="user-about">{user.about}</p>
          </div>
          <div className="summary">
            <div className="left-block">
              <p className={"user-address"}>{user.address.country}, {user.address.city},<br/> {user.address.street}. {user.address.suite}</p>
              <div className="phone-block">
                <p>{user.phone1}</p>
                <p>{user.phone2}</p>
                <p>{user.phone3}</p>
              </div>
              <p className={"user-email"}>{user.email}</p>
            </div>
            <div className="right-block">
              <p className={`user-available-work ${user.availableWork ? "ready" : "busy"}`}>{user.availableWork ? t("profile page.ready to work") : t("profile page.busy")}</p>
              <p className={`user-online ${user.online ? "online" : "offline"}`}>{user.online ? "online" : "offline"}</p>
              <p className={"user-since"}>{t("profile page.joined")} {`${sinceDate}, ${sinceMonth}, ${sinceYear}`}</p>
            </div>
          </div>
        </section>
      </header>
      <div className="main-content">
        <div className="user-rate-block">
          <h3>{t("rating.title")}</h3>
          <div className="user-rate-wrapper">
            <div className="content-inner-block">
              <img className="user-rate-icon" src="/image/icons/icon-great.svg" alt="icon rate"/>
              <div className={"user-description-wrapper"}>
                <span className={"user-rate-description"}>{t("rating.great")}</span>
                <p className={"finally-jobs"}>75% {t("rating.great reviews")}<br/> (75 {t("rating.jobs")})</p>
              </div>
              <Link to={"#"} className={"read-review-button"}>{t("rating.read reviews")} <Reviews/></Link>
            </div>
          </div>
        </div>
        {user.categories.length >= 1 && <div className="user-categories-block">
          <h3>{t("profile page.categories.title")}:</h3>
          <button type={"button"} className={categoriesOpen ? " open" : ""} onClick={() => setCategoriesOpen(!categoriesOpen)}>{t("profile page.categories.show all")}
            <KeyboardArrowDownRoundedIcon/></button>
          <div className={`user-categories-wrapper ${categoriesOpen ? " open" : ""}`}>
            <ul className="user-categories-content">
              {user.categories.map((item: any, index: any) => <li className="user-categories-content-item" key={index}>{lang === "de" ? item.categoryDe : item.categoryEn}</li>)}
            </ul>
          </div>
        </div>}
        <ExpertOnMap user={user}/>
        {/*{user?.gallery.length && <ExpertGallery user={user}/>}*/}
      </div>
    </div>}
  </div>
};