import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {ReactComponent as CustomSelectArrow} from "../assets/image/icons/custom-select-arrow.svg";

interface SelectBoundsProps {
  bounds:any[]
}

export const SelectBounds:React.FC<SelectBoundsProps> = ({bounds}) => {
  const [age, setAge] = React.useState('0');

  const onChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  return (
    <div className={"select-bound-wrapper"}>
      <FormControl sx={{minWidth: 120}}>
        <Select
          displayEmpty
          labelId="select-bound-label"
          id="select-bound"
          IconComponent={CustomSelectArrow}
          value={age}
          onChange={onChange}
        >
          <MenuItem value={"0"}>
            0 km
          </MenuItem>
          {bounds.map((bound, index) =>
            <MenuItem
              key={index}
              value={bound}
            >
              +{bound} km
            </MenuItem>)}
        </Select>
      </FormControl>
    </div>
  );
}