import React from 'react';
import {useTranslation} from "react-i18next";

interface TryServiceKartBlockProps {
  onSwitchForm: (event:  React.MouseEvent<HTMLButtonElement>) => void;
}

export const TryServiceKartBlock: React.FC<TryServiceKartBlockProps> = ({onSwitchForm}) => {
  const {t} = useTranslation();

  return <div className={"try-service-kart-block"}>
    <div className="content">
      <p className="looking-specialist-block">
        {t("try service kart.looking specialist")}
      </p>
      <h2 >{t("try service kart.title")}</h2>
      <div className="button-block">
        <button className="button create-an-order" type={"button"}>{t("try service kart.create order button")}</button>
        <p className={"or"}>{t("try service kart.or")}</p>
        <button className="login-by-specialist" type={"button"} value={"signUp"} onClick={event => onSwitchForm(event)}>{t("try service kart.become specialist button")}</button>
      </div>
    </div>
  </div>
};