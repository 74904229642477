import React, {createContext, useState} from 'react';

export const AuthContext = createContext<object | null | string | any>(null);

export const AuthProvider:React.FC = ({children}) => {
  const [user, setUser] = useState<IUser | null>(firstInit);

  function firstInit() {
      const user: string | null = localStorage.getItem("user");
    return user ? JSON.parse(user) : null;
  }

  const signIn = (user: IUser) => {
    setUser(user);
    /*fetch('', {
      method: "PUT",
      body: JSON.stringify({
        online: true
      })
    })
      .catch(error => console.log(error))*/
  }
  
  const signUp = (user: any) => {
    console.log(user);

  }

  const signOut = () => {
    setUser(null);
    /*fetch('', {
      method: "PUT",
      body: JSON.stringify({
        online: false,
        lastVisit:  new Date()
      })
    })
      .catch(error => console.log(error))*/
    localStorage.removeItem("user");
  }

  const value = {user, signIn, signUp, signOut, setUser}

  return <AuthContext.Provider value={value}>
    {children}
  </AuthContext.Provider>
}