import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {ToastContainer, toast} from 'react-toastify';
import {LoginForm} from "../components/LoginForm";
import {RegistrationForm} from "../components/RegistrationForm";
import {useAuth} from "../components/hook/useAuth";

interface LoginPageProps {
  onSwitchForm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  regLog: string;
  lang: string
}

export const AuthPage: React.FC<LoginPageProps> = ({onSwitchForm, regLog, lang}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {signIn} = useAuth();
  // const {signUp} = useAuth();

  const passwordError: () => void = () => toast.warning(`${lang === "en" ? "Username or password is wrong" : "Benutzername oder Passwort ist falsch"}`, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

  const wentWrong = (error: any) => toast.error(`${error.statusText}`, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

  const emailAlready = () => toast.warning('Email already exists', {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

  const usernameAlready = () => toast.warning('Username already exists', {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  });

  // @ts-ignore
  const fromPage = location.state?.from?.pathname || "/";

  function getUser(users: IUser[], setSubmitButtonState: React.Dispatch<React.SetStateAction<boolean>>, username: string, password: string) {
    setSubmitButtonState(false);
    if (users.find(user => user.username === username && user.password === password)) {
      const user: IUser | undefined = users.find(user => user.username === username && user.password === password);
      localStorage.setItem("user", JSON.stringify(user));
      signIn(user);
      navigate(fromPage, {replace: true});
    } else {
      passwordError();
    }
  }

  return <div className={"auth-page"}>
    <div className="content">
      {regLog === "signUp" ?
        <RegistrationForm onSwitchForm={onSwitchForm}
                          wentWrong={wentWrong}
                          emailAlready={emailAlready}
                          usernameAlready={usernameAlready}/>
        :
        <LoginForm onSwitchForm={onSwitchForm}
                   getUser={getUser}
                   wentWrong={wentWrong}/>}

    </div>
    <ToastContainer/>
  </div>
};