import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {ReactComponent as NotFoundPageMan} from "../assets/image/not-found-page-man.svg";

interface NotfoundPageProps {
  lang: string
}

export const NotfoundPage: React.FC<NotfoundPageProps> = ({lang}) => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(
      () => {
        navigate("/", {replace: true});
      }
      , 2000);
    return () => {
    }
  }, []);

  return <div className={"not-found-page"}>
    <div className="wrapper">
      <NotFoundPageMan/>
      <div className="text-block">
        <p>404</p>
        <p>Not FOUND</p>
      </div>
    </div>
  </div>
}