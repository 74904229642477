import React, {Suspense, useEffect, useState} from 'react';
import {Routes, Route, useNavigate} from "react-router-dom";
import "./assets/scss/main.scss"
import {Layout} from "./components/Layout";
import {Homepage} from "./pages/Homepage";
import {CategoriesPage} from "./pages/СategoriesPage";
import {NotfoundPage} from "./pages/NotfoundPage";
import {SubcategoriesPage} from "./pages/SubcategoriesPage";
import {ScrollToTopButton} from "./components/ScrollToTopButton";
import {AuthPage} from "./pages/AuthPage";
import {RequireAuth} from "./components/hoc/RequireAuth";
import {AccountPage} from "./pages/AccountPage";
import {CreateTaskPage} from "./pages/CreateTaskPage";
import {TaskPage} from "./pages/TaskPage";
import {AuthProvider} from "./components/hoc/AuthProvider";
import {ProfilePage} from "./pages/ProfilePage";

export const App: React.FC = () => {
  const [lang, setLang] = useState<string>(localStorage.getItem("I18N_LANGUAGE") || "de");
  const [regLog, setRegLog] = useState<string>("");
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [testimonials, setTestimonials] = useState<ITestimonials[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [showHeader, setShowHeader] = useState<boolean>(false);
  const [center, setCenter] = useState<ILocation>({
    lat: 50.9578353,
    lng: 6.8272406
  });

  const navigate = useNavigate();

  /*function getPosition () {
    navigator.geolocation.getCurrentPosition(function (geoPosition) {
        let lat = geoPosition ? geoPosition.coords.latitude : 50.4497115;
        let lon = geoPosition ? geoPosition.coords.longitude : 30.5235707;
        setCenter((prev) => {
          return {
            ...prev,
            lat: lat,
            lng: lon
          }
        });
      },
      function (error) {
        console.log(error);
      }
    );
  }*/

  useEffect(() => {
    const urlTestimonials: URL = new URL("/db/testimonials.json", origin);
    fetching(urlTestimonials, setTestimonials);

    const urlCategories: URL = new URL("/db/categories.json", origin);
    fetching(urlCategories, setCategories);

    const urlUsers: URL = new URL("/db/users.json", origin);
    fetching(urlUsers, setUsers);

    //getPosition ();
  }, []);

  function fetching(urlName: URL, stateName: React.Dispatch<React.SetStateAction<IUser[] | ITestimonials[] | any>>) {
    const url = new URL(urlName, origin);
    fetch(url)
      .then(response => response.json())
      .then(data => stateName(data))
      .catch(error => console.log(error))
  }

  function onSwitchForm(event: React.MouseEvent<HTMLButtonElement>): void {
    // @ts-ignore
    setRegLog(event.target.value);
    navigate(`/auth`, {replace: false, state: event.target.value});
  }

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 300) {
      setShowHeader(true);
    } else if (window.scrollY === 0) {
      setShowHeader(false);
    }
  });

  // {categories && categories.forEach((item:any) => console.log(item.categoryDe.toLowerCase().split(" ").join("-")));}

  return <Suspense fallback={""}>
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route path={"/"} element={<Layout showHeader={showHeader} onSwitchForm={onSwitchForm} lang={lang} setLang={setLang}/>}>
            <Route index element={<Homepage onSwitchForm={onSwitchForm} categories={categories} lang={lang} testimonials={testimonials}/>}/>
            <Route path={"/:categoryLink"} element={<CategoriesPage categories={categories} lang={lang} testimonials={testimonials}/>}/>
            <Route path={"/:categoryLink/:subcategoryLink"}
                   element={<SubcategoriesPage setCenter={setCenter} center={center} users={users} categories={categories} lang={lang} testimonials={testimonials}/>}/>
            {<Route path={"account"} element={
              <RequireAuth>
                <AccountPage lang={lang} categories={categories} setRegLog={setRegLog}/>
              </RequireAuth>
            }/>}
            <Route path={"profile/:userId"} element={<ProfilePage users={users} lang={lang}/>}/>
            //TODO Create global path "augfabe"
            <Route path={"aufgabe/:aufgabeId"} element={<TaskPage/>}/>
            <Route path={"aufgabe/:aufgabeId/bearbeiten"} element={
              <RequireAuth>
                <CreateTaskPage/>
              </RequireAuth>
            }/>
            <Route path={"auth"} element={<AuthPage lang={lang}
                                                    onSwitchForm={onSwitchForm}
                                                    regLog={regLog}/>}>
            </Route>
            <Route path={"*"} element={<NotfoundPage lang={lang}/>}/>
          </Route>
        </Routes>
      </AuthProvider>
      <ScrollToTopButton showHeader={showHeader}/>
    </div>
  </Suspense>
}