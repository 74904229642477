import * as React from 'react';
import {useTranslation} from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import {ReactComponent as CustomSelectArrow} from "../assets/image/icons/custom-select-arrow.svg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 400,
    }
  },
};

interface MultipleSelectCategoriesProps {
  categories: any,
  lang: string
}

export const MultipleSelectCategories: React.FC<MultipleSelectCategoriesProps> = ({categories, lang}) => {
  const {t} = useTranslation();
  const [personName, setPersonName] = React.useState<string[]>([]);

  const onChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: {value},
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div className={"select-categories-wrapper"}>
      <FormControl sx={{m: 1}}>
        <Select
          labelId="select-categories-label"
          multiple
          id="select-categories"
          value={personName}
          onChange={onChange}
          IconComponent={CustomSelectArrow}
          displayEmpty
          renderValue={(selected) => {
            if (categories.subcategories && !selected.length) {
              return t("select cat");
            } else if(!categories.subcategories) {
              return <span>{lang === "de" ? categories.categoryDe : categories.categoryEn}</span>
            } else return selected.join(', ');
          }}
          MenuProps={MenuProps}
          classes={{}}
        >
          {categories && categories.subcategories ? categories.subcategories.map((item: any) => (
            <MenuItem key={lang === "de" ? item.categoryDe : item.categoryEn} value={lang === "de" ? item.categoryDe : item.categoryEn}>
              <Checkbox checked={personName.indexOf(lang === "de" ? item.categoryDe : item.categoryEn) > -1}/>
              <ListItemText primary={lang === "de" ? item.categoryDe : item.categoryEn}/>
            </MenuItem>
          )) : <MenuItem>
            <Checkbox checked/>
            <ListItemText primary={lang === "de" ? categories.categoryDe : categories.categoryEn}/>
          </MenuItem>
          }
        </Select>
      </FormControl>
    </div>
  );
}
