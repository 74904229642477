import React, {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import logo from "../assets/image/logo2.png";
import {ReactComponent as ArrowRight} from "../assets/image/icons/ArrowRight.svg";
import {ReactComponent as FB} from "../assets/image/icons/FB.svg";
import {ReactComponent as Twitter} from "../assets/image/icons/Twitter.svg";
import {ReactComponent as Inst} from "../assets/image/icons/Inst.svg";

interface FooterProps {
  lang: string
}

export const Footer: React.FC<FooterProps> = ({lang}) => {
  const {t} = useTranslation();
  const [orders, setOrders] = useState<Array<any>>([]);

  useEffect(() => {
    const url: URL = new URL("/db/orders.json", origin);
    fetch(url)
      .then(data => data.json())
      .then(data => setOrders(data));
  },[]);

  return (
    <footer>
      <div className="content">
        <div className="about">
          <div className="about-wrapper">
            <h3>{t("footer.aboutTitle")}</h3>
            <p className="about-content">{t("footer.aboutDes")}</p>
          </div>
          <div className="terms">
            <a href="#" className={"link"}>{t("footer.terms")}</a>
            <a href="#" className={"link"}>{t("footer.PP")}</a>
          </div>
        </div>
        <div className="orders">
          <div className="orders-wrapper">
            <h3>{t("footer.recentTitle")}</h3>
            <div className="orders-block">
              <ul className="orders-list">
                {orders.length >= 1 && (lang === "de" ? orders.map((item, index) => <li key={index} className={"order-item"}>
                  <a href="#" className="user-page"><img src={item.avatar} alt="avatar"/></a>
                  <p className={"user-task-des"}>{item.name} {item.secondName} hat in <a href="#" className="user-city link">{item.city}</a> die Aufgabe <a href={"#"} className="user-task-title link-black">{item.title}</a> erstellt
                  </p>
                </li>) : orders.map((item, index) => <li key={index} className={"order-item"}>
                  <a href="#" className="user-page"><img src={item.avatar} alt="avatar"/></a>
                  <p className={"user-task-des"}>{item.name} {item.secondName} created the <a href={"#"} className="user-task-title link-black">{item.title}</a> task in <a href="#" className="user-city link">{item.city}</a>
                  </p>
                </li>))}
              </ul>
            </div>
          </div>
        </div>
        <div className="contacts">
          <div className="contacts-wrapper">
            <h3>{t("footer.contactsTitle")}</h3>
            <div className="contacts-block">
              <p className="address">{t("footer.address")}</p>
              <p className="email">{t("footer.email")}: servicekart@gmail.com</p>
              <p className="phone">{t("footer.phone")}: +49 (0) 321 4567 890</p>
            </div>
            <div className="callback-form">
              <p className={"callback-form__header"}>
                {t("footer.needConsult")}
                <span>{t("footer.leaveNumber")}</span>
              </p>
              <form action={"#"} method={"POST"} className="input-block">
                <label className={"number-label"} htmlFor="number">
                  <input type="tel" id="number" className="number" placeholder={"+49 (хх) ххх хх хх"}/>
                  <button type={"button"}><ArrowRight/></button>
                </label>
              </form>
            </div>
          </div>
          <div className="social-block">
            <a href={"#"} className={"link"}><FB/>Facebook</a>
            <a href={"#"} className={"link"}><Twitter/>Twitter</a>
            <a href={"#"} className={"link"}><Inst/>Instagram</a>
          </div>
        </div>
      </div>
      <div className="rights">
        <img src={logo} className={"logo"} alt="logo"/>
        <p className={"copyright"}>Copyright © 2022-{new Date().getFullYear()}. Service Kart. {t("footer.copyrights")}</p>
      </div>
    </footer>
  )
}