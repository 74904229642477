import React from "react";

interface WorkBlockItemProps {
  count: number,
  workImage: string,
  header: string,
  description: string
}

export const WorkBlockItem: React.FC<WorkBlockItemProps> = ({count, workImage, header, description}) => {

  return (
    <div className="work-block__item">
      <div className="work-block__image-block">
        <div className="count">{count}</div>
        <img src={workImage} alt={"step " + count}/>
      </div>
      <div className="work-block__description-block">
        <h3>{header}</h3>
        <p className="mini-description">{description}</p>
      </div>
    </div>
  );
}