import React, {useState, useEffect} from 'react';
import {useTranslation} from "react-i18next";
// import {Link} from 'react-router-dom';
import {useAuth} from '../components/hook/useAuth';
// import {AccountUserGallery} from "../components/AccountUserGallery";
import {DatePickerComponent} from "../components/DatePickerComponent";
import {styled, FormGroup, FormControlLabel, Switch, SwitchProps, Stack, Typography, FormControl, Checkbox} from '@mui/material';
import {ReactComponent as Unchecked} from "../assets/image/icons/unchecked.svg";
import {ReactComponent as Checked} from "../assets/image/icons/checked.svg";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import {ReactComponent as Pencil} from "../assets/image/icons/pencil.svg";
import {ReactComponent as DeleteAccount} from "../assets/image/icons/delete-account.svg";
// import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import {IndeterminateCheckbox} from "../components/Indeterminate";

interface AccountPageProps {
  lang: string;
  categories: any[];
  setRegLog: React.Dispatch<React.SetStateAction<string>>;
}

export const AccountPage: React.FC<AccountPageProps> = ({lang, categories, setRegLog}) => {
  const {t} = useTranslation();
  const [role, setRole] = useState<boolean>();
  const [isOrganization, setIsOrganization] = useState<boolean>();
  const [work, setWork] = useState<boolean>();
  const {user} = useAuth();
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [description, setDescription] = useState("");
  const [phoneInputStatus, SetPhoneInputStatus] = useState(true);
  const [hiddenButton, setHiddenButton] = useState(true);
  const [onAddress, setHandleAddress] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [email, setEmail] = useState("");
  const [about, setAbout] = useState("");
  const [twoFactorAuth, setTwoFactorAuth] = useState(false);
  const [categoriesOpen, setCategoriesOpen] = useState(false);
  const [notification, setNotification] = useState({
    offers: false,
    messages: true,
    status: false,
    payment: true
  });

  useEffect(() => {
    if (user) {
      setName(user.name);
      setLastName(user.lastName);
      setDescription(user.description);
      setPhone1(user.phone1);
      setPhone2(user.phone2);
      setPhone3(user.phone3);
      setEmail(user.email);
      setAbout(user.about);
      setRole(() => user.role === "expert");
      setIsOrganization(user.isOrganization);
      setWork(user.availableWork);
      setNotification(prev => {
        return {
          ...prev,
          offers: user.notifications.notifyOffers,
          messages: user.notifications.notifyMessages,
          status: user.notifications.notifyOrdersStatusChanges,
          payment: user.notifications.notifyPaymentPeriod
        }
      });
      setTwoFactorAuth(user.twoFactorAuth);
    }
  }, [user]);

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))
  (({theme}) => ({
    width: 68,
    height: 36,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 4,
      transitionDuration: '300ms',
      color: '#759A8A',
      '&.Mui-checked': {
        transform: 'translateX(32px)',
        color: '#FFF',
        transitionDuration: '300ms',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#759A8A' : '#759A8A',
          opacity: 1,
          border: '2px solid #759A8A',
          transitionDuration: '300ms',
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#759A8A',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 28,
      height: 28,
      boxShadow: 'none',
    },
    '& .MuiSwitch-track': {
      border: '2px solid #759A8A',
      borderRadius: 34,
      backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#FFFFFF',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const onChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.checked);
  };

  const onChangeWork = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWork(event.target.checked);
  };

  const onChangeTwoFactorAuth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTwoFactorAuth(event.target.checked);
  }

  const onChangeNotifications = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotification({
      ...notification,
      [event.target.name]: event.target.checked,
    });
  };

  const {offers, messages, status, payment} = notification;
  // const error = [offers, messages, status, payment].filter((v) => v).length !== 2;

  /*const onClearValue = (event) => {
    console.log(event.target.parentNode);
  }*/

  const onChangeMainNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone1(event.target.value);
    setHiddenButton(false);
  }

  return (user ? <div className={"account-page"}>
    <h1>{t("account page.title")}</h1>
    <div className={"content"}>
      <div className="left-block">
        <div className="personal-data-block">
          <h3>{t("account page.personal data.title")}</h3>
          <div className="personal-data-wrapper">
            <img src={user.avatar} alt="avatar" className="avatar"/>
            <div className="data-block">
              <p className="username"><span>{user.name}</span> <span className="user-int">{user.lastName}</span></p>
              <div className="contact-block">
                <p className="user-phone user-phone1">{user.phone1}</p>
                {user.phone2 && <p className="user-phone user-phone2">{user.phone2}</p>}
                {user.phone3 && <p className="user-phone user-phone3">{user.phone3}</p>}
                <p className="user-email">{user.email}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="switch-block">
          <FormControl>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{t("account page.switch block.client")}</Typography>
              <IOSSwitch sx={{m: 1}} checked={role} onChange={onChangeRole}/>
              <Typography>{t("account page.switch block.expert")}</Typography>
            </Stack>
          </FormControl>
          {user.role === "expert" && <FormControl>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>{t("account page.switch block.available work")}</Typography>
              <IOSSwitch sx={{m: 1}} checked={work} onChange={onChangeWork}/>
              <Typography>{t("account page.switch block.unavailable work")}</Typography>
            </Stack>
          </FormControl>}
        </div>
        <div className="notifications-block">
          <h3>{t("account page.notification block.title")}</h3>
          <div className="notifications-wrapper">
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={offers} onChange={onChangeNotifications} name="offers"/>
                  }
                  label={t("account page.notification block.offers")}
                />
                <FormControlLabel
                  control={
                    <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={messages} onChange={onChangeNotifications} name="messages"/>
                  }
                  label={t("account page.notification block.messages")}
                />
                <FormControlLabel
                  control={
                    <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={status} onChange={onChangeNotifications} name="status"/>
                  }
                  label={t("account page.notification block.status")}
                />
                <FormControlLabel
                  control={
                    <Checkbox icon={<Unchecked/>} checkedIcon={<Checked/>} checked={payment} onChange={onChangeNotifications} name="payment"/>
                  }
                  label={t("account page.notification block.payment")}
                />
              </FormGroup>
            </FormControl>
          </div>
        </div>
        <div className="auth-block">
          <h3>{t("account page.auth block.title")}</h3>
          <div className="auth-wrapper">
            <FormControl>
              <FormControlLabel
                control={<IOSSwitch sx={{m: 1}} checked={twoFactorAuth} onChange={onChangeTwoFactorAuth}/>}
                label={t("account page.auth block.turn auth")}
                labelPlacement="start"
              />
            </FormControl>
          </div>
        </div>
        {/*{user && <AccountUserGallery user={user}/>}*/}
      </div>
      <div className="right-block">
        <div className="edit-personal-data">
          <form action="#" method={"POST"}>
            <div className={"input-block"}>
              <p>{t("account page.edit personal data.name")}*</p>
              <div className="wrapper">
                <label htmlFor="edit-username">
                  <input type="text" id={"edit-username"} value={name} onChange={event => setName(event.target.value)} placeholder={"Name"}/>
                </label>
              </div>
            </div>
            <div className={"input-block"}>
              <p>{t("account page.edit personal data.lastname")}*</p>
              <div className="wrapper">
                <label htmlFor="edit-user-last-name">
                  <input type="text" id={"edit-user-last-name"} value={lastName} onChange={event => setLastName(event.target.value)} placeholder={"Last name"}/>
                </label>
              </div>
            </div>
            {user && <div className="username-block">
              <p>{t("account page.edit personal data.username")}</p>
              <p className="username">{user.username}</p>
            </div>}
            <div className={'input-block'}>
              <p>{t("account page.edit personal data.description.title")}</p>
              <div className="wrapper">
                <textarea id={"user-short-description"}
                          placeholder={t("account page.edit personal data.description.placeholder")}
                          maxLength={170}
                          value={description}
                          onChange={(event) => setDescription(event.target.value)}/>
                <p className={"desLength"}>{description.length}/170</p>
              </div>
            </div>
            <div className={"button-block"}>
              <button type={"submit"}>{t("apply")}</button>
            </div>
          </form>
        </div>
        <div className="since-address-about-block">
          <form action="#" method={"POST"}>
            <div className={"date-picker-block"}>
              {user.isOrganization ? <p>{t("account page.since")}</p> : <p>{t("account page.birthday")}</p>}
              <div className="wrapper">
                <DatePickerComponent since={user.since}/>
              </div>
            </div>
            <div className={"user-address-block input-block"}>
              <p>{t("account page.address")}*</p>
              <div className="wrapper">
                <label htmlFor="autocomplete" className={"user-address-label"}>
                  {user && <input type="text" id={"autocomplete"} value={`${user.address.street} ${user.address.suite}, ${user.address.city}, ${user.address.country}`}
                                  onChange={event => setHandleAddress(event.target.value)} className={"user-address"}/>}
                </label>
              </div>
            </div>
            <div className={"user-about-block input-block"}>
              <p>{t("account page.about")}</p>
              <div className="wrapper">
                <textarea name="user-about" value={about} onChange={event => setAbout(event.target.value)} id="user-about"/>
              </div>
            </div>
            <div className={"button-block"}>
              <button type={"submit"}>{t("apply")}</button>
            </div>
          </form>
        </div>
        {user?.categories?.length && <div className="user-categories-block">
          <h3>{t("account page.about")}: <Pencil /></h3>
          <button type={"button"} className={categoriesOpen ? " open" : ""} onClick={() => setCategoriesOpen(!categoriesOpen)}>
            {t("account page.more categories")} <KeyboardArrowDownRoundedIcon/>
          </button>
          <div className={`user-categories-wrapper ${categoriesOpen ? " open" : ""}`}>
            <ul className="user-categories-content">
              {user.categories.map((item: any, index: number) => <li className="user-categories-content-item" key={index}>{lang === "de" ? item.categoryDe : item.categoryEn}</li>)}
            </ul>
          </div>
        </div>}
        <div className="phone-password-email">
          <div className="user-phone-edit-block">
            <h3>{t("account page.phone.title")}: </h3>
            <div className="user-phone-edit-wrapper">
              <form action="#" method={"POST"}>
                <div className={"phone1-block input-block"}>
                  <p>{t("account page.phone.main number")}*</p>
                  <div className="wrapper">
                    <label htmlFor="phone1">
                      <input type="tel" id={"phone1"} className={"phone1"} value={phone1} onChange={event => onChangeMainNumber(event)} disabled={phoneInputStatus}/>
                    </label>
                    <Pencil onClick={() => SetPhoneInputStatus(false)}/>
                  </div>
                  <div className={"button-block" + (hiddenButton ? " hidden" : "")}>
                    <button type={"submit"}>{t("verify")}</button>
                  </div>
                </div>
              </form>
              <form action="#" method={"POST"}>
                <div className={"phone2-block input-block"}>
                  <p>{t("account page.phone.optional number")} 1</p>
                  <div className="wrapper">
                    <label htmlFor="phone2">
                      <input type="tel" id={"phone2"} className={"phone2"} value={phone2} onChange={event => setPhone2(event.target.value)}/>
                    </label>
                  </div>
                </div>
                <div className={"phone3-block input-block"}>
                  <p>{t("account page.phone.optional number")} 2</p>
                  <div className="wrapper">
                    <label htmlFor="phone3">
                      <input type="tel" id={"phone3"} className={"phone3"} value={phone3} onChange={event => setPhone3(event.target.value)}/>
                    </label>
                  </div>
                </div>
                <div className={"button-block"}>
                  <button type={"submit"}>{t("apply")}</button>
                </div>
              </form>
            </div>
          </div>
          <div className="change-password-block">
            <h3>{t("account page.password.title")}:</h3>
            <div className="change-password-wrapper">
              <form action="#" method={"POST"}>
                <div className={"old-password-block input-block"}>
                  <p>{t("account page.password.old")}</p>
                  <div className="wrapper">
                    <label htmlFor="old-password">
                      <input type="password" id={"old-password"} className={"old-password"}/>
                    </label>
                  </div>
                </div>
                <div className={"new-password-block input-block"}>
                  <p>{t("account page.password.new")}</p>
                  <div className="wrapper">
                    <label htmlFor="new-password">
                      <input type="password" id={"new-password"} className={"new-password"}/>
                    </label>
                  </div>
                </div>
                <div className={"confirm-password-block input-block"}>
                  <p>{t("account page.password.confirm")}</p>
                  <div className="wrapper">
                    <label htmlFor="confirm-password">
                      <input type="password" id="confirm-password" className="confirm-password"/>
                    </label>
                  </div>
                </div>
                <div className={"button-block"}>
                  <button type={"submit"}>{t("apply")}</button>
                </div>
              </form>
            </div>
          </div>
          <div className={"change-email-block"}>
            <h3>{t("account page.email.title")}:</h3>
            <div className="change-email-wrapper">
              <div className="show-current-email">
                <p className="help-text">{t("account page.email.current")}*</p>
                <p className="current-user-email">{user && user.email}</p>
              </div>
              <form action="#" method={"POST"}>
                <div className={"new-user-email-block input-block"}>
                  <p>{t("account page.email.new")}</p>
                  <div className="wrapper">
                    <label htmlFor="new-user-email">
                      <input type="email" id="new-user-email" className="new-user-email" value={email} onChange={event => setEmail(event.target.value)}/>
                    </label>
                  </div>
                </div>
                <div className={"button-block"}>
                  <button type={"submit"}>{t("verify")}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="delete-account-wrapper">
          <button type={"button"}>{t("account page.delete profile")} <DeleteAccount/></button>
        </div>
      </div>
      {/*<IndeterminateCheckbox />*/}
    </div>
  </div> : <div style={{height: '110vh'}}></div>)
};