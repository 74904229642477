import React from 'react';
import TextField from '@mui/material/TextField';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// import {StaticDatePicker} from '@mui/x-date-pickers/StaticDatePicker';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';

interface DatePickerComponentProps {
  since: number
}

export const DatePickerComponent: React.FC<DatePickerComponentProps> = ({since}) => {
  const [value, setValue] = React.useState<Date>(new Date(since));
  const minDate: Date = new Date('1940-01-01T00:00:00.000');
  const maxDate: Date = new Date();

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    {/*<StaticDatePicker
      displayStaticWrapperAs="desktop"
      openTo="year"
      minDate={minDate}
      maxDate={maxDate}
      value={value}
      views={['year', 'month', 'day']}
      onChange={(newValue) => {
        setValue(newValue);
      }}
      renderInput={(params) => <TextField {...params} />}
    />*/}
    <DatePicker
      disableFuture
      minDate={minDate}
      maxDate={maxDate}
      openTo="year"
      views={['year', 'month', 'day']}
      value={value}
      onChange={(value): void => {
        if (value) {
          setValue(value);
        }
      }}
      renderInput={(params: any) => <TextField {...params} placeholder={"mm/dd/yyyy"}/>}
    />
  </LocalizationProvider>
};