import React from "react";
import { useTranslation } from 'react-i18next';
import {ReactComponent as SearchIcon} from "../assets/image/icons/SearchFilled.svg";

export const SearchForm:React.FC = () => {
  const { t } = useTranslation();

  return(
    <div className="search-block">
      <form action="#" method={"GET"}>
        <div className="search-group">
          <input type="search" className={"search autocomplete"} role={"search"} placeholder={t("search.placeholder")}/>
          <button type={"button"} className={"button search-button"}><SearchIcon/></button>
        </div>
        {/*<span className="example">{t("search.example")}</span>*/}
      </form>
    </div>
  );
}