import React, {useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {CategoryBlock} from "../components/CategoryBlock";
import {HowDoWeWork} from "../components/HowDoWeWork/HowDoWeWork";
import {Testimonials} from "../components/Testimonials/Testimonials";
import {SearchForm} from "../components/SearchForm";
import {BannerBlock} from "../components/BannerBlock";

interface SubcategoriesPageProps {
  categories: any,
  lang: string,
  testimonials: ITestimonials[]
}

export const CategoriesPage: React.FC<SubcategoriesPageProps> = ({categories, lang, testimonials}) => {
  const navigate = useNavigate();
  const {categoryLink} = useParams();
  const [category, setCategory] = useState<object | null>(null);

  useEffect(() => {
    if (categories.length) {
      setCategory(categories.find((item: any) => item.categoryLink === categoryLink));
    }
  }, [categories]);

  useEffect(() => {
    if(category === undefined) {
      navigate("/", {replace: true});
    }
  }, [category]);

  useEffect(() => {
    return () => {
    }
  }, []);

  return <div>
    <main className="main-content">
      <div className="content">
        {/*@ts-ignore*/}
        <h1>{category !== null && (lang === "de" ? category.categoryDe : category.categoryEn)}</h1>
      </div>
        <BannerBlock/>
      <div className="content">
        <SearchForm/>
        {/*@ts-ignore*/}
        {category !== null && <CategoryBlock categories={category.subcategories} lang={lang}/>}
      </div>
        <HowDoWeWork/>
      <div className="content">
        {testimonials.length && <Testimonials testimonials={testimonials}/>}
      </div>
    </main>
  </div>
}