import React from "react";
import {ReactComponent as Quote} from "../../assets/image/icons/quote.svg";
import noAvatar from "../../assets/image/icons/noAvatar.png";

interface TestimonialSliderProps {
  userAvatar: string,
  username: string,
  userLastName: string,
  userCity: string,
  userCountry: string,
  userText: string
}

export const TestimonialSlider:React.FC<TestimonialSliderProps> = ({userAvatar, username, userLastName, userCity, userCountry, userText}) => {

  return (
    <div className="testimonial">
      <Quote />
      <div className="user-meta-block">
        <img className={"user-avatar"} src={userAvatar || noAvatar} alt="avatar"/>
        <div className="user-meta-wrapper">
          <div className="user-full-name"><span className="username">{username}</span> <span className="user-last-name">{userLastName}</span></div>
          <div className="user-place"><span className="user-city">{userCity}</span>, <span className="user-country">{userCountry}</span></div>
        </div>
      </div>
      <div className="user-text">{userText}</div>
    </div>
  );
}