import React from "react";
import {Outlet} from "react-router-dom";
import {Header} from "./Header";
import {Footer} from "./Footer";

interface LayoutProps {
  onSwitchForm: (event: React.MouseEvent<HTMLButtonElement>) => void,
  lang: string,
  setLang: React.Dispatch<React.SetStateAction<string>>,
  showHeader: boolean
}

export const Layout:React.FC<LayoutProps> = ({onSwitchForm, lang, setLang, showHeader}) => {
  return <div className={"container"}>
    <Header showHeader={showHeader} onSwitchForm={onSwitchForm} lang={lang} setLang={setLang}/>
    <main style={showHeader ? {marginTop: "92px"} : {marginTop: "0"}}>
      <Outlet/>
    </main>
    <Footer lang={lang}/>
  </div>
}