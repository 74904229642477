import React from "react";
import {Link} from "react-router-dom";

interface CategoryBlockProps {
  categories: any,
  lang: string
}

export const CategoryBlock: React.FC<CategoryBlockProps> = ({categories, lang}) => {

  return (
    <div className="categories-block">
      <div className="category-button-block">
        {categories && categories.map((item: any, index: number) => <Link key={index}
                                                                          to={item.categoryLink}
                                                                          className={"category-button"}
                                                                          style={{
                                                                            backgroundImage:`url(${item.icon})`,
                                                                            backgroundSize: "cover",
                                                                            backgroundPosition: "center"
                                                                            }}>
                                                                               <span className="category">{lang === "de" ? item.categoryDe : item.categoryEn}</span>
                                                                              </Link>
        )}
      </div>
    </div>
  );
}