import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface LoginComponentProps {
  onSwitchForm: (event: React.MouseEvent<HTMLButtonElement>) => void,
  getUser: (users: IUser[], setFormValid: React.Dispatch<React.SetStateAction<boolean>>, username: string, password: string) => void;
  wentWrong: (error: any) => void;
}

export const LoginForm: React.FC<LoginComponentProps> = ({onSwitchForm, getUser, wentWrong}) => {
  const {t} = useTranslation();
  
  
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  
  const [usernameError, setUsernameError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const [passType, setPassType] = useState<boolean>(true);

  const [formValid, setFormValid] = useState<boolean>(false);
  
  const onBlurUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setUsernameError('Это поле не может быть пустым');
    } else setUsernameError('');
  };
  
  const onBlurPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!event.target.value) {
      setPasswordError('Это поле не может быть пустым');
    } else setPasswordError('');
  };
  
  const onUsernameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    onBlurUsername(event);
  }
  
  const onPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    onBlurPassword(event);
  }

  useEffect(() => {
    if (username && password) {
      setFormValid(true);
    } else setFormValid(false);
  }, [username, password]);

  const onSignIn = (event: any, getUser: (users: IUser[], setFormValid: React.Dispatch<React.SetStateAction<boolean>>, username: string, password: string) => void, setFormValid: React.Dispatch<React.SetStateAction<boolean>>) => {
    event.preventDefault();
    setFormValid(true);
    const form = event.target;
    const username = form.username.value.trim();
    const password = form.password.value.trim();

    if(username && password) {
      const url: URL = new URL('/db/users.json', origin);
      fetch(url)
        .then(response => response.json())
        .then((users: IUser[]) => getUser(users, setFormValid, username, password))
        .catch(error => wentWrong(error))
        .finally(form.reset());
    } return
  };

  return <div className="form login-form">
    <div className="closeButtonWrapper">
      <CloseRoundedIcon className={"closeButton"}/>
    </div>
    <h3>{t("loginForm.title")}</h3>
    <div className="form-block">
      <form action="src/components/LoginForm#" method={"POST"} name={"login"} onSubmit={event => onSignIn(event, getUser, setFormValid)}>
        <div className={`input-container ${usernameError ? 'error' : ''}`}>
          <input type="text"
                 className="form-input"
                 autoFocus
                 required
                 id="username"
                 name={"username"}
                 onBlur={(event) => onBlurUsername(event)}
                 value={username}
                 onChange={(event) => onUsernameInput(event)}
                 placeholder={" "}/>
          <div className="cut">{"Username"}</div>
          <label className={"placeholder"} htmlFor="username">{"Username"}</label>
        </div>
        {usernameError && <div className={'error-alert'}>{usernameError}</div>}
        <div className={`input-container ${passwordError ? 'error' : ''}`}>
          <input type={passType ? "password":"text"}
                 className="form-input"
                 required
                 id="password"
                 name={"password"}
                 onBlur={(event) => onBlurPassword(event)}
                 value={password}
                 onChange={(event) => onPasswordInput(event)}
                 placeholder={" "}/>
          {password && (passType ?
            <VisibilityOutlinedIcon onClick={() => setPassType(!passType)}/> :
            <VisibilityOffOutlinedIcon onClick={() => setPassType(!passType)}/>)}
          <div className="cut">{t("loginForm.password")}</div>
          <label className={"placeholder"} htmlFor="password">{t("loginForm.password")}</label>
        </div>
        {passwordError && <div className={'error-alert'}>{passwordError}</div>}
        <button type={"submit"} disabled={!formValid} className="button continue">{t("loginForm.continue")}</button>
        <div className="wrapper">
          <div className="remember-me">
            <div className="custom-check">
              <input type="checkbox" className="check" id={"check"} value={"remember-me"} defaultChecked/>
              <label htmlFor="check">{t("loginForm.rememberMe")}</label>
            </div>
          </div>
          <button type={'button'} className={"link-black"}>{t("loginForm.forgotPass")}</button>
        </div>
      </form>
      <div className="orWrapper">
        <span className={"or"}>{t("loginForm.or")}</span>
      </div>
      <div className="social-buttons-block">
        {/*<button type={"button"} className="social-button facebook-button">{t("loginForm.socBtn")} Facebook</button>*/}
        <button type={"button"} className="social-button google-button">Google</button>
        <button type={"button"} className="social-button apple-button">Apple</button>
        {/*<button type={"button"} className="social-button microsoft-button">{t("loginForm.socBtn")} Microsoft</button>*/}
      </div>
    </div>
    <div className="form-footer">
      <div className="form-footer-wrapper">
        <span>{t("loginForm.nonReg")}</span>
        <button type={"button"} value={"signUp"} onClick={event => onSwitchForm(event)} className={"signIn-button link-black"}>{t("loginForm.join")}</button>
      </div>
    </div>
  </div>
}