import React from "react";
import { useTranslation } from 'react-i18next';

import workImage1 from "../../assets/image/howDoWeWork/work-image1.png";
import workImage2 from "../../assets/image/howDoWeWork/work-image2.png";
import workImage3 from "../../assets/image/howDoWeWork/work-image3.png";
import {WorkBlockItem} from "./WorkBlockItem";
import {CreateOrderBlock} from "../CreateOrderBlock";

export const HowDoWeWork:React.FC = () => {
  const { t } = useTranslation();
 //TODO Component needs to be modified
  return(
    <div className="how-do-we-work">
      <div className="content">
        <h2><span>{t("we work.title.part1")}</span>{t("we work.title.part2")}</h2>
        <div className="work-block">
          <WorkBlockItem count={1} workImage={workImage1} header={t("we work.step1.title")} description={t("we work.step1.description")}/>
          <WorkBlockItem count={2} workImage={workImage2} header={t("we work.step2.title")} description={t("we work.step2.description")}/>
          <WorkBlockItem count={3} workImage={workImage3} header={t("we work.step3.title")} description={t("we work.step3.description")}/>
        </div>
        <CreateOrderBlock>
          <p>{t("we work.create order description")}</p>
        </CreateOrderBlock>
      </div>
    </div>
  )
}