import React, {useEffect} from "react";
import {useTranslation} from 'react-i18next';
// Components
import {SearchForm} from "../components/SearchForm";
import {BannerBlock} from "../components/BannerBlock";
import {CategoryBlock} from "../components/CategoryBlock";
import {HowDoWeWork} from "../components/HowDoWeWork/HowDoWeWork";
import {Testimonials} from "../components/Testimonials/Testimonials";
import {TryServiceKartBlock} from "../components/TryServiceKartBlock";

interface HomePageProps {
  categories: any,
  lang: string,
  testimonials: ITestimonials[],
  onSwitchForm: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const Homepage: React.FC<HomePageProps> = ({categories, onSwitchForm, lang, testimonials}) => {
  const {t} = useTranslation("translation");

  useEffect(() => {
    return () => {
    }
  }, []);

  return <div className="homepage">
    <div className="content">
      <h1><span>{t("h1.part1")}</span>{t("h1.part2")}</h1>
    </div>
    <BannerBlock/>
    <div className="content">
      <SearchForm/>
      {categories.length && <CategoryBlock categories={categories} lang={lang}/>}
    </div>
      <HowDoWeWork/>
    <div className="content">
      {testimonials.length && <Testimonials testimonials={testimonials}/>}
    </div>
    <TryServiceKartBlock onSwitchForm={onSwitchForm}/>
  </div>
}