import React from 'react';
import {useLocation, Navigate} from "react-router-dom";
import {useAuth} from "../hook/useAuth"

// @ts-ignore
export const RequireAuth = ({children})  => {
  const location = useLocation();
  const {user} = useAuth();

  if(!user) {
    return <Navigate to="/auth" replace={true} state={{from: location}}/>
  } else return children
};