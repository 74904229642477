import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import {useAuth} from "./hook/useAuth";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface RegistrationComponentProps {
  onSwitchForm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  wentWrong: (error: any) => void;
  emailAlready: () => void;
  usernameAlready: () => void;
}

export const RegistrationForm: React.FC<RegistrationComponentProps> = ({onSwitchForm, wentWrong, usernameAlready, emailAlready}) => {
  const [role, setRole] = useState("expert");
  const [termsPolicyCheck, setTermsPolicyCheck] = useState<boolean>(true);

  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [emailError, setEmailError] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');
  const [confirmPolicyError, setConfirmPolicyError] = useState<string>('');

  const [formValid, setFormValid] = useState<boolean>(false);

  const [passType, setPassType] = useState<boolean>(true);
  const [confirmPassType, setConfirmPassType] = useState<boolean>(true);

  const onBlurEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regexp = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!event.target.value) {
      setEmailError('Это поле не может быть пустым');
    } else if (!regexp.test(String(event.target.value).toLowerCase())) {
      setEmailError('Имейл не корректный')
    } else {
      setEmailError('');
    }
  };

  const onBlurUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setUsernameError('Это поле не может быть пустым');
    } else if (event.target.value.length < 3) {
      setUsernameError('username must be more then 3 characters');
    } else {
      setUsernameError('');
    }
  };

  const onBlurPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    // let specialCharacters = new RegExp("(?=.*[!@#\$%\^&\*])");
    if (!event.target.value) {
      setPasswordError('Это поле не может быть пустым');
    } else if (!strongRegex.test(String(event.target.value))) {
      setPasswordError('Password must be at least 8 characters long and contain upper and lower case letters and at least 1 number');
    } else if (confirmPassword && event.target.value !== confirmPassword) {
      setConfirmPasswordError('Password and Confirm Password does not match');
    } else {
      setPasswordError('');
      setConfirmPasswordError('');
    }
  };

  const onBlurConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setConfirmPasswordError('Это поле не может быть пустым');
    } else if (password && event.target.value !== password) {
      setConfirmPasswordError('Password and Confirm Password does not match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const onEmailInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    onBlurEmail(event);
  };

  const onUsernameInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    onBlurUsername(event);
  };

  const onPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    onBlurPassword(event);
  };

  const onConfirmPasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
    onBlurConfirmPassword(event);
  }

  useEffect(() => {
    if (!termsPolicyCheck) {
      setConfirmPolicyError('You must confirm Terms of service and Privacy Policy')
    } else setConfirmPolicyError('');
    if (email && username && password && (password === confirmPassword) && termsPolicyCheck) {
      setFormValid(true);
    } else setFormValid(false);
  }, [email, username, password, confirmPassword, termsPolicyCheck]);

  const {t} = useTranslation();
  const {signUp} = useAuth();

  function onChangeRole(event: any): void {
    setRole(event.target.value);
  }

  const onRegister = (event: any) => {
    event.preventDefault();
    const form = event.target;
    const userRole = role;
    const email = form.email.value.trim();
    const username = form.username.value.trim();
    const password = form.password.value.trim();
    const url: URL = new URL('');
    fetch(url, {
      method: "PUT",
      body: JSON.stringify({
        role: userRole,
        email,
        username,
        password
      })
    })
      .then(response => response.json())
      .then(user => signUp(user))
      .catch(error => wentWrong(error))
      .finally(form.reset());
  };

  return <div className="form registration-form">
    <div className="closeButtonWrapper">
      <CloseRoundedIcon className={"closeButton"}/>
    </div>
    <h3>{t("regForm.title")}</h3>
    <div className="form-block">
      <form action="src/components/RegistrationForm#" method={"POST"} onSubmit={event => onRegister(event)}>
        <div className="role-button-tabs">
          <button type={"button"} onClick={event => onChangeRole(event)} value={"client"}
                  className={"role-button" + (role === "client" ? " active" : "")}>{t("regForm.client")}</button>
          <button type={"button"} onClick={event => onChangeRole(event)} value={"expert"}
                  className={"role-button" + (role === "expert" ? " active" : "")}>{t("regForm.expert")}</button>
        </div>
        <div className={`input-container ${emailError ? 'error' : ''}`}>
          <input type="email"
                 className="form-input"
                 onBlur={((event: React.FocusEvent<HTMLInputElement>) => onBlurEmail(event))}
                 name={"email"}
                 value={email}
                 onChange={(event) => onEmailInput(event)}
                 required
                 id="email"
                 placeholder={" "}
          />
          <div className="cut">{"Email"}</div>
          <label className={"placeholder"} htmlFor="email">{"Email"}</label>
        </div>
        {emailError && <div className={'error-alert'}>{emailError}</div>}
        <div className={`input-container ${usernameError ? 'error' : ''}`}>
          <input type="login"
                 className="form-input"
                 onBlur={((event: React.FocusEvent<HTMLInputElement>) => onBlurUsername(event))}
                 name={"username"}
                 value={username}
                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => onUsernameInput(event)}
                 required
                 id="username"
                 placeholder={" "}
          />
          <div className="cut">{"Username"}</div>
          <label className={"placeholder"} htmlFor="username">{"Username"}</label>
        </div>
        {usernameError && <div className={'error-alert'}>{usernameError}</div>}
        <div className={`input-container ${passwordError ? 'error' : ''}`}>
          <input type={passType ? "password" : "text"}
                 className="form-input"
                 onBlur={((event: React.FocusEvent<HTMLInputElement>) => onBlurPassword(event))}
                 name={"password"}
                 value={password}
                 onChange={(event) => onPasswordInput(event)}
                 required
                 id="password"
                 placeholder={" "}
          />
          {password && (passType ?
            <VisibilityOutlinedIcon onClick={() => setPassType(!passType)}/> :
            <VisibilityOffOutlinedIcon onClick={() => setPassType(!passType)}/>)}
          <div className="cut">{t("regForm.password")}</div>
          <label className={"placeholder"} htmlFor="password">{t("regForm.password")}</label>
        </div>
        {passwordError && <div className={'error-alert'}>{passwordError}</div>}
        <div className={`input-container ${confirmPasswordError ? 'error' : ''}`}>
          <input type={confirmPassType ? "password" : "text"}
                 className="form-input"
                 onBlur={((event: React.FocusEvent<HTMLInputElement>) => onBlurConfirmPassword(event))}
                 name={"confirmPassword"}
                 value={confirmPassword}
                 onChange={(event) => onConfirmPasswordInput(event)}
                 required
                 id="confirm-password"
                 placeholder={" "}
          />
          {confirmPassword && (confirmPassType ?
            <VisibilityOutlinedIcon onClick={() => setConfirmPassType(!confirmPassType)}/> :
            <VisibilityOffOutlinedIcon onClick={() => setConfirmPassType(!confirmPassType)}/>)}
          <div className="cut">{t("regForm.confirmPassword")}</div>
          <label className={"placeholder"} htmlFor="confirm-password">{t("regForm.confirmPassword")}</label>
        </div>
        {confirmPasswordError && <div className={'error-alert'}>{confirmPasswordError}</div>}
        <div className="terms-n-policy">
          <div className="custom-check">
            <input type="checkbox"
                   className="terms-n-policy-check"
                   id={"terms-n-policy-check"}
                   value={"terms-n-policy-check"}
                   checked={termsPolicyCheck}
                   onChange={() => setTermsPolicyCheck(!termsPolicyCheck)}
            />
            <label htmlFor="terms-n-policy-check">
              {t("regForm.agree")}&nbsp;<a href="src/components/RegistrationForm#" className={"link-black"}>{t("regForm.termsOfService")}&nbsp;</a>
              {t("regForm.and")}&nbsp;<a href="src/components/RegistrationForm#" className={"link-black"}>{t("regForm.PP")}</a>
            </label>
          </div>
          {confirmPolicyError && <div className={'error-alert'}>{confirmPolicyError}</div>}
        </div>
        <button type={"submit"} disabled={!formValid} className="button continue">{t("regForm.continue")}</button>
      </form>
      <div className="orWrapper">
        <span className={"or"}>{t("regForm.or")}</span>
      </div>
      <div className="social-buttons-block">
        {/*<button type={"button"} className="social-button facebook-button">{t("regForm.socBtn")} Facebook</button>*/}
        <button type={"button"} className="social-button google-button">Google</button>
        <button type={"button"} className="social-button apple-button">Apple</button>
        {/*<button type={"button"} className="social-button microsoft-button">{t("regForm.socBtn")} Microsoft</button>*/}
      </div>
    </div>
    <div className="form-footer">
      <div className="form-footer-wrapper">
        <span>{t("regForm.member")}</span>
        <button type={"button"} value={"logIn"} onClick={(event) => onSwitchForm(event)} className={"signIn-button link-black"}>{t("regForm.logIn")}</button>
      </div>
    </div>
  </div>
}