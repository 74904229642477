import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ExpertBlock} from "../components/ExpertBlock";
import {GoogleMapComponent} from "../components/GoogleMapComponent";

interface ExpertPageProps {
  categories: any,
  lang: string,
  testimonials: ITestimonials[],
  users: IUser[],
  center: ILocation,
  setCenter: React.Dispatch<React.SetStateAction<ILocation>>
}

export const SubcategoriesPage: React.FC<ExpertPageProps> = ({categories, lang, testimonials, users, center, setCenter}) => {
  const {categoryLink} = useParams();
  const {subcategoryLink} = useParams();
  const [category, setCategory] = useState<object | null>(null);
  const [subcategory, setSubcategory] = useState<object | null>(null);

  useEffect(() => {
    if (categories.length) {
      setCategory(categories.find((item: any) => item.categoryLink === categoryLink));
    }

  }, [categories]);

  useEffect(() => {
    if (category) {
      // @ts-ignore
      setSubcategory(category.subcategories.find((item: any) => item.categoryLink === subcategoryLink));
    }
  }, [category]);

  useEffect(() => {
    return () => {
    }
  }, []);

  return <div>
    <main className="main-content">
      <div className="content">
        <h1>{category !== null && subcategory !== null &&
          // @ts-ignore
          (lang === "de" ? subcategory.categoryDe : subcategory.categoryEn)}</h1>
      </div>
      {subcategory !== null && <GoogleMapComponent lang={lang} center={center} users={users} categories={subcategory}/>}
      <div className="content">
        <ExpertBlock lang={lang} users={users}/>
      </div>
    </main>
  </div>
}